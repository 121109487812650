import React from "react";
import DropdownQuestion from "../components/dropdownQuestion/DropdownQuestion";
import { parseFaqs, parseMapPoints, parseGallery, parseNumbers } from "./utils";
import Gallery from "../components/gallery/Gallery";
import AtlasSubPages from "../pages/atlas/AtlasSubPages";
import AsalvadorSubPages from "../pages/asalvador/AsalvadorSubPages";
import MapPoints from "../components/mapPoints/MapPoints";
import NumbersIcvm from "../components/NumbersIcvm/NumbersIcvm";

export const getContentList = (postContent) => {
  const div = document.createElement("div");
  div.innerHTML = postContent;
  const content = div.getElementsByClassName("content");

  if (!content || !content[0]) return false;
  const elements = content && content[0].children[0].children;

  const items = [].slice.call(elements);
  return items;
};

export const renderPreItem = (item) => {
  switch (item.className) {
    case "wp-block-preformatted atlas":
      return <AtlasSubPages />;
    case "wp-block-preformatted asalvador":
      return <AsalvadorSubPages />;
    case "wp-block-preformatted questions":
      return <DropdownQuestion questions={parseFaqs(item.outerHTML)} />;
    case "wp-block-preformatted questions gray":
      return <DropdownQuestion questions={parseFaqs(item.outerHTML)} gray />;
    case "wp-block-preformatted map":
      return <MapPoints points={parseMapPoints(item.outerHTML)} />;
    default:
      return <DropdownQuestion questions={parseFaqs(item.outerHTML)} />;
  }
};

export const renderDivItem = (item) => {
  switch (item.className) {
    case "wp-block-group numbers-icvm-group":
      return <NumbersIcvm numbers={parseNumbers(item.outerHTML)} />;
    default:
      return <div dangerouslySetInnerHTML={{ __html: item.outerHTML }} />;
  }
};

export const renderItem = (item) => {
  switch (item.tagName) {
    case "PRE":
      return renderPreItem(item);
    case "FIGURE":
      return <Gallery imageArray={parseGallery(item.outerHTML)} />;
    case "DIV":
      return renderDivItem(item);
    default:
      return <div dangerouslySetInnerHTML={{ __html: item.outerHTML }} />;
  }
};

export const buildPageContent = (content) => (
  <div className="project-content-wrapper">
    {content.map((item) => renderItem(item))}
  </div>
);
