import React, { useEffect, useState } from "react";
import "./AtlasSubPages.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { getCertificadoSucceeded } from "../../../actions/actions";
import { ASALVADOR_ABA_FORMULARIO } from "../../../constants/constants.api";
import { getPost } from "../../../sagas/posts";
import { getCertificado } from "../../../selectors/atlas";
import Loader from "../../../components/loader/Loader";
import { getContentList, buildPageContent } from "../../../utils/pageBuilder";

const CertificadoIcvm = ({ onGetCertificadoSucceeded, post }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPost(ASALVADOR_ABA_FORMULARIO, onGetCertificadoSucceeded, () =>
      setLoading(false)
    );
  }, [onGetCertificadoSucceeded, setLoading]);

  const postContentBuilt = post && getContentList(post.content.rendered);

  return (
    <div>
      {loading ? (
        <Loader medium />
      ) : (
        <div className="atlas-subpage-content formulario-de-contacto">
          {post && (
            <div>
              {postContentBuilt ? (
                buildPageContent(postContentBuilt)
              ) : (
                <div
                  className="project-content-wrapper"
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(getCertificado, (post) => ({
  post,
}));

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetCertificadoSucceeded: getCertificadoSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CertificadoIcvm);
