export const BASE_URL = "http://icvm.pt/en/wp-json/wp/v2/";

export const ATUALIDADES_CATEGORY_ID = 5;
export const ATUALIDADES_CATEGORY_ICVM_ID = 41;
export const ATUALIDADES_CATEGORY_GLOBAL_ID = 42;
export const CONFERENCIAS_CATEGORY_ID = 3;
export const LIVROS_CATEGORY_ID = 31;
export const PUBLICACAO_CATEGORY_ID = 32;
export const PROJETO_CATEGORY_ID = 33;
export const PROJETO_POST_CATEGORY_ID = 35;
export const CERTIFICADO_CATEGORY_ID = 37;
export const CERTIFICADO_ESPAÇO_PUBLICO_CATEGORY_ID = 43;
export const CERTIFICADO_NIVEL_1_CATEGORY_ID = 38;
export const CERTIFICADO_NIVEL_2_CATEGORY_ID = 39;
export const CERTIFICADO_NIVEL_3_CATEGORY_ID = 40;

export const SLIDESHOW_POST_ID = 2303;
export const FAQS_POST_ID = 2352;
export const ATLAS_POST_ID = 2490;
export const ATLAS_CERTIFICADO_ICVM_POST_ID = 2475;
export const ATLAS_NIVEIS_ACESSIBILIDADE_POST_ID = 2477;
export const ATLAS_PARCERIAS_POST_ID = 2485;
export const PROJECT_LIST_POST_ID = 3269;

export const ASALVADOR_POST_ID = 6622;
export const CERTIFICADO_ASALVADOR_CATEGORY_ID = 46;
export const CERTIFICADO_ASALVADOR_NIVEL_1_CATEGORY_ID = 47;
export const CERTIFICADO_ASALVADOR_NIVEL_2_CATEGORY_ID = 48;
export const CERTIFICADO_ASALVADOR_NIVEL_3_CATEGORY_ID = 49;
export const CERTIFICADO_ASALVADOR_COMPROMISSO_CATEGORY_ID = 50;
export const ASALVADOR_ABA_FORMULARIO = 6638;
export const ASALVADOR_PARCERIAS_POST_ID = 6641;