import React from "react";
import "./AtlasSubPages.css";
import ContactosForm from "../../contactos/ContactosForm";

const pdfLink =
  "https://associacaosalvador.com/wp-content/uploads/2023/10/REGULAMENTO-Selo-da-Acessibilidade_2023.pdf";

const RegulamentoAdesao = () => (
  <div className="contactos-section">
    <div className="contactos-feedback-section">
      <div className="contactos-feedback-wrapper">
        <div className="contactos-feedback-left-wrapper">
          <div className="contactos-feedback-title">Adesão</div>
          {/* Para aderir ao Certificado de Acessibilidade ICVM deverá enviar a
          seguinte informação através de um dos meios infra indicados:
          <div className="contactos-feedback-regulation">Ver Regulamento ↗</div> */}
          <div className="contactos-feedback-subtitle">Correio</div>
          Rua do Passeio Alegre, nº 20
          <br />
          4150-570 Porto – Portugal
          <div className="contactos-feedback-subtitle">Email</div>
          geral@institutodemobilidade.org
          <div className="contactos-feedback-subtitle">Tel/Fax</div>
          (+351) 228 328 115
          <div className="contactos-feedback-subtitle">Regulamento</div>
          <a target="_blank" rel="noopener noreferrer" href={pdfLink}>
            Saber mais ↗
          </a>
        </div>
        <div className="contactos-feedback-right-wrapper">
          <ContactosForm subject="Adesão" />
        </div>
      </div>
    </div>
  </div>
);

export default RegulamentoAdesao;
